import "./Billing.scss";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useHistory } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { basePathName } from "../../Utilities/HelperData";
import ReactPixel from "react-facebook-pixel";
import { toast } from "react-toastify";
import { useGlobalDispatch, useGlobalState, GlobalActionTypes } from "../../Context";
import authService from "../../Components/ApiAuthorization/AuthorizeService";
import { Container } from "reactstrap";
import { LoadingSection } from "../../Components/Display";
import PublicLayout from "../../PublicLayout";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { constants } from "../../Utilities/Constants";
import ValidationErrors from "../Errors/ValidationErrors";

export const Billing = () => {
  const history = useHistory();
  const apiWorker = useApiWorker();
  const [serverErrors, setServerErrors] = useState<any>(null);
  const dispatch = useGlobalDispatch();
  const {
    currentUser,
    customerSupportContactPhoneNumber,
    customerSupportContactEmail,
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [redirectAfterSubscriptionCreate, setRedirectAfterSubscriptionCreate] =
    useState(false);
  const genericError = `There was an unexpected error with your request, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`;
  const displayError = (error: any) => {
    setServerErrors(error);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const stripePromise = loadStripe(
    process.env.NODE_ENV === "development"
      ? process.env?.REACT_APP_STRIPE_KEY || ""
      : window.REACT_APP_STRIPE_KEY || ""
  );
  const createFreeSubscription = async (selectedPlan: any) => {
    let exceptionThrown = false;
    setIsLoading(true);
    const result = await apiWorker
      .post<any>(
        `${basePathName}/api/billing/createfreesubscription`,
        {
          campaignId: selectedPlan.campaignId,
          productId: selectedPlan.productId,
          promoCode: null,
          productName: selectedPlan.name,
        },
        {}
      )
      .catch((err) => {
        displayError(genericError);
        setIsLoading(false);
        exceptionThrown = true;
      });
    if (exceptionThrown) {
      setRedirectAfterSubscriptionCreate(false);
    }
    if (result && result.data) {
      window.sessionStorage.removeItem(constants.SelectedSubscriptionSession);
      ReactPixel.track("Purchase", {
        currency: "USD",
        value: 0,
        content_name: selectedPlan.name,
        contents: [
          {
            id: selectedPlan.productId,
            quantity: 1,
          },
        ],
      });
      window.gtag("event", "purchase", {
        non_interaction: true,
        transaction_id: result.data.id,
        value: 0,
        currency: "USD",
        tax: 0,
        items: [
          {
            id: selectedPlan.productId,
            name: selectedPlan.name,
            quantity: 1,
            price: 0,
          },
        ],
      });
      //everflow
      window.EF.conversion({
        aid: 1,
        amount: 0,
        email: currentUser?.name ?? "",
        order_id: result.data?.id ?? "",
        adv1: selectedPlan.productId,
        adv2: result.data.items?.data[0]?.id ?? "",
        adv3: "50",
        adv4: "therapist.com",
        adv5: "therapist.com",
        verification_token: "bSeohq5dkpOaJdGjwSYmDxvlJnhXfN",
      });

      toast.success(
        "Welcome! Your account has been created and you can start creating your public profile"
      );
      await authService.changeUserToRegistered();
      dispatch({
        type: GlobalActionTypes.SetSubscriptionStatus,
        payload: "registered",
      });
      setRedirectAfterSubscriptionCreate(true);
    } else {
      displayError(genericError);
      setIsLoading(false);
      setRedirectAfterSubscriptionCreate(false);
    }
  };
  const passedDataString = window.sessionStorage.getItem(constants.SelectedSubscriptionSession) ?? ""
  const selectedPlan = passedDataString !== "" ? JSON.parse(passedDataString) : null;

  useEffect(() => {
    if (currentUser) {
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
    }
  }, [currentUser]);

  useEffect(() => {
    if (passedDataString === "") {
      history.push("/usersubscription");
      return;
    }
    if ((selectedPlan.isFreeTrial && !selectedPlan.isCreditCardRequired) || selectedPlan.price < 0.01) {
      createFreeSubscription(selectedPlan);
    }
  }, [])

  useEffect(() => {
    if (redirectAfterSubscriptionCreate) {
      history.push("/provider-profile");
    }
  }, [redirectAfterSubscriptionCreate, history]);
  return (
    <Container fluid={true} className="sub-root">
      <LoadingSection isLoading={isLoading} isFullPage={true}>
        <PublicLayout>
          <Fragment>
            <div id="progress-wrapper">
              <div className="progress-control">
                <div className="step step-1">
                  <div className="circle completed-step">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="check"
                      className="check-circle svg-inline--fa fa-check fa-w-16 fa-1x "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                      ></path>
                    </svg>
                  </div>
                  <div className="step-header">STEP 1</div>
                </div>
                <div className="step step-2">
                  <div className="circle completed-step">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="check"
                      className="check-circle svg-inline--fa fa-check fa-w-16 fa-1x "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                      ></path>
                    </svg>
                  </div>
                  <div className="step-header">STEP 2</div>
                </div>
                <div className="step step-3">
                  <div className="circle current-step"></div>
                  <div className="step-header current-step">STEP 3</div>
                </div>
                <div className="progress-bar progress-3"></div>
              </div>
            </div>
            <div className="text-center">
              <ValidationErrors
                serverErrors={serverErrors}
                formErrors={{}}
                customErrors={[]}
              />
            </div>
            <div>
              {selectedPlan && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm selectedPlan={selectedPlan} />
                </Elements>
              )}
            </div>
          </Fragment>
        </PublicLayout>
      </LoadingSection>
    </Container>
  );
};
